<template>
  <main>
    <b-card>
      <b-row>
        <b-col cols="12" class="">
          <b-tabs>
            <b-tab :active="true">
              <template #title>
                {{ $t('Pick Up') }} <b-badge class="ml-1" variant="primary">{{ pickUp.length }}</b-badge>
              </template>
              <b-table hover responsive :items="pickUp" :fields="fieldspengiriman">
                <template #head(checkbox)>
                  <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                </template>
                <template #head(alamat)>
                  {{ $t('Alamat Pickup & Waktu Pickup ') }}<b-link :title="$t('Atur Masal Waktu Pickup')" size="sm"
                    class="text-dark" @click.prevent="
                      aturmass()
                      ">
                    <b-badge variant="primary"><feather-icon icon="EditIcon" /></b-badge>
                  </b-link>
                  <b-modal size="xl" id="aturmass-modal" ref="aturmassModal" title="Atur Massal Pickup" hide-footer>
                    <b-table :items="formattedProviders" :fields="[
                      { key: 'logisticsProviderName', label: 'Logistics Provider Name' },
                      { key: 'addresses', label: 'Addresses' },
                      { key: 'pickUpTimeList', label: 'Pickup Time List' }
                    ]" striped hover responsive="sm">
                      <template #cell(addresses)="data">
                        <b-form-select v-model="selectedAddresses[data.index]" :options="data.item.addressesOptions"
                          class="mb-2"></b-form-select>
                      </template>
                      <template #cell(pickUpTimeList)="data">
                        <b-form-select v-model="selectedPickupTimes[data.index]" :options="data.item.pickupTimeOptions"
                          class="mb-2"></b-form-select>
                      </template>
                    </b-table>
                    <b-button @click="applySelections">Terapkan</b-button>
                  </b-modal>
                </template>
                <template #cell(checkbox)="{ item }">
                  <b-form-checkbox v-model="selectedginee" :value="item"></b-form-checkbox>
                </template>
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>
                <template #cell(pesanan)="{ item }">
                  <span><b-img :src="getImageUrl(item.channel)" style="width: 15px;" :title="item.channel" /></span>
                  {{ item.externalOrderId }}<br>
                  {{ item.orderStatus }}
                </template>
                <template #cell(kurir)="{ item }">
                  {{ item.logisticsInfos ? item.logisticsInfos[0].logisticsProviderName : null }}<br>
                  {{ item.logisticsInfos ? item.logisticsInfos[0].logisticsTrackingNumber : null }}
                </template>
                <template #cell(alamat)="{ item }">
                  <b-form-select v-model="item.selected">
                    <!-- Slot untuk mengubah opsi pertama, jika diperlukan -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Pilih salah satu</b-form-select-option>
                    </template>
                    <!-- Slot untuk setiap opsi -->
                    <template v-for="option in item.addresses">
                      <b-form-select-option :value="option">
                        {{ option.address }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-select v-if="item.selected" v-model="item.selected2">
                    <!-- Slot untuk mengubah opsi pertama, jika diperlukan -->
                    <template #first>
                      <b-form-select-option :value="null" disabled>Pilih salah satu</b-form-select-option>
                    </template>
                    <!-- Slot untuk setiap opsi -->
                    <template v-for="option in item.selected.pickupTimeList">
                      <b-form-select-option :value="option.pickupTimeId">
                        {{ option.timeText !== ""
                          ? option.timeText
                          : formatTimestamp(option.pickupStartTime).slice(0, 5) + ' - ' +
                          formatTimestamp(option.pickupEndTime).slice(0, 5) + ' ' +
                          formatTimestamp(option.pickupStartTime).slice(6) }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </template>
                <template #cell(action)="{ item }">
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                    aturpengirimansingle(item)
                    ">
                    {{ $t('Arrange Shipment') }}
                  </b-link>
                  <br>
                </template>
              </b-table>
            </b-tab>
            <b-tab title="Drop Off">
              <template #title>
                {{ $t('Drop Off') }} <b-badge class="ml-1" variant="primary">{{ dropOff.length }}</b-badge>
              </template>

              <b-table small hover responsive :items="dropOff" :fields="fieldspengiriman">

                <template #head(checkbox)>
                  <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                </template>
                <template #cell(checkbox)="{ item }">
                  <b-form-checkbox v-model="selectedginee" :value="item"></b-form-checkbox>
                </template>
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                <template #cell(pesanan)="{ item }">
                  {{ item.externalOrderId }}

                </template>

                <template #cell(kurir)="{ item }">
                  {{ item.logisticsInfos ? item.logisticsInfos[0].logisticsProviderName : null }}<br>
                  {{ item.logisticsInfos ? item.logisticsInfos[0].logisticsTrackingNumber : null }}<br><br>
                  <!-- {{ item.customerInfo ? item.customerInfo.name : null }}<br>
              {{ item.customerInfo ? item.customerInfo.mobile : null }}<br><br>
              {{ $t('Note By Buyer') }} : <br> -->
                  <!-- <template v-if="item.extraInfo.noteByBuyer">
                <p
                  :title="item ? item.extraInfo ? item.extraInfo.noteByBuyer == '' && item.extraInfo.noteByBuyer == null ? null : item.extraInfo.noteByBuyer : null : null">
                  <b-img src="@/assets/images/svg/message.svg" style="width: 20px;" alt="logo" />
                </p>
              </template>
    <template v-else>
                <p class="text-danger">{{ $t('Note not found') }}</p>
              </template> -->
                  <!-- <p><b>{{ item ? item.extraInfo ? item.extraInfo.noteByBuyer : null : null }}</b></p> -->
                </template>
                <template #cell(outbondorder)="{ item }">
                  <b-alert variant="primary" show></b-alert>
                  <template v-for=" hem in item.items">
                    <b-card bg-variant="transparent" no-body class="overflow-hidden">
                      <b-row no-gutters>
                        <b-col md="9">
                          <!-- <b-card-body :title="hem.productName"> -->
                          <b-card-text>
                            <!-- <span :title="hem.productName">{{ truncateString(hem.productName,
														20) }}</span><br>
													{{ hem.variationName }}<br>
													{{ hem.sku }}<br>
													{{ formatRupiah(hem.actualPrice) }} x {{ hem.quantity }} -->
                          </b-card-text>
                          <!-- </b-card-body> -->
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>

                </template>
                <template #cell(status_bayar)="{ item }">
                  <b-badge v-if="item.status_bayar == 'lunas'" variant="success">LUNAS</b-badge>
                  <b-badge v-else variant="danger">BELUM LUNAS</b-badge>
                </template>

                <template #cell(action)="{ item }">
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                    aturpengirimansingle(item)
                    ">
                    <feather-icon icon="CalendarIcon" /> {{ $t('Arrange Shipment') }}
                  </b-link>
                  <br>
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                    printdoc(item)
                    ">
                    <feather-icon icon="PrinterIcon" /> Print Label
                  </b-link>
                  <br>
                  <!-- <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                  printmodalinv(item)
                  ">
                  <feather-icon icon="PrinterIcon" /> Print Invoice
                </b-link>
                <br> -->
                  <!-- <template v-for="hem in item.items"> -->
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="getCustomerFrame(item)">
                    <feather-icon icon="BoxIcon" /> Process to Warehouse
                  </b-link>
                  <!-- </template> -->
                  <!-- <template v-for=" hem in item.items">
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="printdoc(item)"
                    v-if="!hem.productName.match(/lensa/gi)">
                    <feather-icon icon="BoxIcon" /> Process Warehouse
                  </b-link>
                </template> -->
                  <b-button v-if="
                    item.transaction_type == 'jasa' &&
                    item.status != 'dibatalkan'
                  " size="sm" variant="outline-primary" @click.prevent="modalTerapis(item)"
                    v-b-tooltip.hover.top="'Pilih Terapis'">
                    <feather-icon icon="UserIcon" />
                  </b-button>
                  <b-button v-if="
                    item.status == 0 &&
                    allowUpdate() &&
                    item.tak_tertagih == 0 &&
                    item.bayar == 0
                  " size="sm" variant="outline-warning" @click.prevent="
                    $router.push(`/transaksi-penjualan/edit/${item.id}`)
                    ">
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>
                <template #cell(actionFinance)="{ item }">
                  <b-button size="sm" variant="warning" @click.prevent="
                    $router.push(`/transaksi-penjualan/detail/${item.id}`)
                    ">
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button title="Konfirmasi Pembayaran Manual" v-if="item.status_bayar != 'lunas'" size="sm"
                    variant="primary" @click="aksiKonfirmasiawal(item)">
                    <feather-icon icon="ListIcon" />
                  </b-button>
                  <b-button title="Cek Pembayaran Otomatis" v-if="
                    item.status_bayar != 'lunas' &&
                    item.payment_option == 'pg_midtrans'
                  " size="sm" variant="info" @click="aksiKonfirmasipg(item)">
                    <feather-icon icon="DollarSignIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-tab>
            <b-tab title="Manual Ship">
              <template #title>
                {{ $t('Manual Ship') }} <b-badge class="ml-1" variant="primary">{{ manualShip.length }}</b-badge>
              </template>

              <b-table small hover responsive :items="dropOff" :fields="fieldspengiriman">

                <template #head(checkbox)>
                  <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                </template>
                <template #cell(checkbox)="{ item }">
                  <b-form-checkbox v-model="selectedginee" :value="item"></b-form-checkbox>
                </template>
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                <template #cell(pesanan)="{ item }">
                  {{ item.externalOrderId }}

                </template>

                <template #cell(kurir)="{ item }">
                  {{ item.logisticsInfos ? item.logisticsInfos[0].logisticsProviderName : null }}<br>
                  {{ item.logisticsInfos ? item.logisticsInfos[0].logisticsTrackingNumber : null }}<br><br>
                  <!-- {{ item.customerInfo ? item.customerInfo.name : null }}<br>
              {{ item.customerInfo ? item.customerInfo.mobile : null }}<br><br>
              {{ $t('Note By Buyer') }} : <br> -->
                  <!-- <template v-if="item.extraInfo.noteByBuyer">
                <p
                  :title="item ? item.extraInfo ? item.extraInfo.noteByBuyer == '' && item.extraInfo.noteByBuyer == null ? null : item.extraInfo.noteByBuyer : null : null">
                  <b-img src="@/assets/images/svg/message.svg" style="width: 20px;" alt="logo" />
                </p>
              </template>
    <template v-else>
                <p class="text-danger">{{ $t('Note not found') }}</p>
              </template> -->
                  <!-- <p><b>{{ item ? item.extraInfo ? item.extraInfo.noteByBuyer : null : null }}</b></p> -->
                </template>
                <template #cell(outbondorder)="{ item }">
                  <b-alert variant="primary" show></b-alert>
                  <template v-for=" hem in item.items">
                    <b-card bg-variant="transparent" no-body class="overflow-hidden">
                      <b-row no-gutters>
                        <b-col md="9">
                          <!-- <b-card-body :title="hem.productName"> -->
                          <b-card-text>
                            <!-- <span :title="hem.productName">{{ truncateString(hem.productName,
														20) }}</span><br>
													{{ hem.variationName }}<br>
													{{ hem.sku }}<br>
													{{ formatRupiah(hem.actualPrice) }} x {{ hem.quantity }} -->
                          </b-card-text>
                          <!-- </b-card-body> -->
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>

                </template>
                <template #cell(status_bayar)="{ item }">
                  <b-badge v-if="item.status_bayar == 'lunas'" variant="success">LUNAS</b-badge>
                  <b-badge v-else variant="danger">BELUM LUNAS</b-badge>
                </template>

                <template #cell(action)="{ item }">
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                    aturpengirimansingle(item)
                    ">
                    <feather-icon icon="CalendarIcon" /> {{ $t('Arrange Shipment') }}
                  </b-link>
                  <br>
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                    printdoc(item)
                    ">
                    <feather-icon icon="PrinterIcon" /> Print Label
                  </b-link>
                  <br>
                  <!-- <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                  printmodalinv(item)
                  ">
                  <feather-icon icon="PrinterIcon" /> Print Invoice
                </b-link>
                <br> -->
                  <!-- <template v-for="hem in item.items"> -->
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="getCustomerFrame(item)">
                    <feather-icon icon="BoxIcon" /> Process to Warehouse
                  </b-link>
                  <!-- </template> -->
                  <!-- <template v-for=" hem in item.items">
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="printdoc(item)"
                    v-if="!hem.productName.match(/lensa/gi)">
                    <feather-icon icon="BoxIcon" /> Process Warehouse
                  </b-link>
                </template> -->
                  <b-button v-if="
                    item.transaction_type == 'jasa' &&
                    item.status != 'dibatalkan'
                  " size="sm" variant="outline-primary" @click.prevent="modalTerapis(item)"
                    v-b-tooltip.hover.top="'Pilih Terapis'">
                    <feather-icon icon="UserIcon" />
                  </b-button>
                  <b-button v-if="
                    item.status == 0 &&
                    allowUpdate() &&
                    item.tak_tertagih == 0 &&
                    item.bayar == 0
                  " size="sm" variant="outline-warning" @click.prevent="
                    $router.push(`/transaksi-penjualan/edit/${item.id}`)
                    ">
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>
                <template #cell(actionFinance)="{ item }">
                  <b-button size="sm" variant="warning" @click.prevent="
                    $router.push(`/transaksi-penjualan/detail/${item.id}`)
                    ">
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button title="Konfirmasi Pembayaran Manual" v-if="item.status_bayar != 'lunas'" size="sm"
                    variant="primary" @click="aksiKonfirmasiawal(item)">
                    <feather-icon icon="ListIcon" />
                  </b-button>
                  <b-button title="Cek Pembayaran Otomatis" v-if="
                    item.status_bayar != 'lunas' &&
                    item.payment_option == 'pg_midtrans'
                  " size="sm" variant="info" @click="aksiKonfirmasipg(item)">
                    <feather-icon icon="DollarSignIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
    {{ pickUp }}
  </main>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
  BAlert, BPopover, VBPopover, BFormRadio, BFormRadioGroup, BFormCheckboxGroup, BLink,
  BTable,
  BCard, BCardBody, BCardImg, BCardText, BCardTitle,
  VBTooltip, BTabs, BTab,
  BImg,
  BForm,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormDatepicker, BFormSpinbutton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import mixin from "./mixin";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  mixins: [mixin],
  components: {
    flatPickr,
    jsPDF,
    BFormDatepicker, BPopover, BTabs, BTab, BFormSpinbutton,
    BAlert, BFormRadio, BFormRadioGroup, BFormCheckboxGroup, BLink,
    ToastificationContent,
    FormFilterExport,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BCard, BCardBody, BCardImg, BCardText, BCardTitle,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BImg,
  },
  directives: {
    'b-popover': VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      ship: [],
      test: [],
      itemData: [],
      selectedginee: [],
      fieldspengiriman: [
        {
          key: 'checkbox', label: '#',
          thStyle: { width: '2%' }, tdClass: 'text-left'
        },
        {
          key: "no",
          label: "No",
          thStyle: { width: '2%' }, tdClass: 'text-left'
        },
        { key: "pesanan", label: "Pesanan", thStyle: { width: '20%' }, tdClass: 'text-left' },
        { key: "kurir", label: "Kurir", thStyle: { width: '20%' }, tdClass: 'text-left' },
        { key: "alamat", label: "Alamat Pickup & Waktu Pickup", thStyle: { width: '30%' }, tdClass: 'text-right' },
        { key: "action", label: "Operate", thStyle: { width: '20%' }, tdClass: 'text-left' },
      ],
      allChecked: false,
      pickUp: [],
      dropOff: [],
      manualShip: [],
      selected: null,
      selected2: null,
      selectedAddresses: [],
      selectedPickupTimes: [],
    };
  },
  computed: {
    formattedProviders() {
      const uniqueProviders = {};
      // Gabungkan hasil dari semua order ke dalam objek unik
      this.pickUp.forEach(order => {
        this.separateByLogisticsProvider(order).forEach(provider => {
          // Gunakan providerName sebagai kunci untuk menyimpan penyedia yang unik
          if (!uniqueProviders[provider.logisticsProviderName]) {
            uniqueProviders[provider.logisticsProviderName] = provider;
          }
        });
      });

      // Kembalikan nilai unik sebagai array
      return Object.values(uniqueProviders);
    }
  },
  watch: {

    allChecked(val) {
      if (val) {
        this.selectedginee = this.ship
      }
      else {
        this.selectedginee = []
      }
    },
  },
  methods: {

    formatTimestamp(timestamp) {
      const date = new Date(timestamp * 1000); // Konversi dari detik ke milidetik
      const hours = date.getUTCHours().toString().padStart(2, '0');
      const minutes = date.getUTCMinutes().toString().padStart(2, '0');
      const day = date.getUTCDate().toString().padStart(2, '0');
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = date.getUTCFullYear();

      return `${hours}:${minutes} ${day}-${month}-${year}`;
    },
    applySelections() {
      console.log('tablemodal', this.selectedAddresses[0]);

      // Iterasi setiap provider dalam formattedProviders
      this.formattedProviders.forEach((provider) => {
        // Tentukan selectedAddress dan selectedPickupTime berdasarkan index provider
        const selectedAddress = this.selectedAddresses[0]; // Ambil address pertama
        const selectedPickupTime = this.selectedPickupTimes[0]; // Ambil pickup time pertama (sesuaikan index jika perlu)

        // Terapkan pemilihan ke semua item ship yang sesuai dengan nama penyedia logistik
        this.pickUp.forEach(item => {
          console.log('tablemodal', item.selected = selectedAddress);
          if (item.logisticsInfos[0].logisticsProviderName === provider.logisticsProviderName) {
            item.selected = selectedAddress;
            // Pastikan selectedAddress memiliki properti pickupTimeId
            item.selected2 = selectedAddress?.pickupTimeId || null;
          }
        });
      });
    },

    separateByLogisticsProvider(order, orderIndex) {
      const providers = {};

      order.logistics.forEach(logistic => {
        logistic.logisticDetailList.forEach(detail => {
          const providerName = detail.logisticsProviderName;
          if (providerName) {
            // Jika provider belum ada, inisialisasi
            if (!providers[providerName]) {
              providers[providerName] = {
                logisticsProviderName: providerName,
                addressesOptions: [],
                pickupTimeOptions: []
              };
            }
            // Hanya tambahkan alamat dan waktu pickup jika belum ditambahkan
            if (order.addresses) {
              order.addresses.forEach(address => {
                // Cek jika alamat belum ada dalam addressesOptions
                if (providers[providerName] && !providers[providerName].addressesOptions.some(opt => opt.value === address.addressId)) {
                  providers[providerName].addressesOptions.push({
                    text: address.address,
                    value: address
                  });
                }

                // Tambahkan waktu pickup hanya jika belum ada
                if (address.pickupTimeList) {
                  address.pickupTimeList.forEach(pickupTime => {
                    if (providers[providerName] && !providers[providerName].pickupTimeOptions.some(opt => opt.value === pickupTime.pickupTimeId)) {
                      providers[providerName].pickupTimeOptions.push({
                        text: pickupTime.timeText,
                        value: pickupTime.pickupTimeId
                      });
                    }
                  });
                }
              });
            }
          }
        });
      });

      // Set default selected values untuk setiap index di tabel
      const providerKeys = Object.keys(providers);
      this.selectedAddresses[orderIndex] = providerKeys.length > 0 ? providers[providerKeys[0]].addressesOptions[0]?.value || null : null;
      this.selectedPickupTimes[orderIndex] = providerKeys.length > 0 ? providers[providerKeys[0]].pickupTimeOptions[0]?.value || null : null;

      return Object.values(providers);
    },
    getImageUrl(channel) {
      // Menggunakan URL langsung ke folder public
      return `/assets/svg/${channel}.svg`;
    },
    getShip(data) {
      const payload = {
        orderIds: data.join(","),
      };
      // payload.channel = [this.channel]
      this.loading = true
      this.$store
        .dispatch("ginee/getShip", payload)
        .then((res) => {
          console.log("testdata", res.data.data)
          this.ship = res.data.data;
          // this.test = res.data.data;
          // this.totalRows = res.data.recordsTotal;
          this.itemData.forEach(newOrder => {
            const matchingShip = this.ship.find(shipOrder => shipOrder.orderId === newOrder.orderId);

            if (matchingShip) {
              // Jika orderId ditemukan, gabungkan data dari newOrder ke matchingShip
              Object.assign(matchingShip,
                { externalOrderId: newOrder.externalOrderId },
                { channel: newOrder.channel },
                { logisticsInfos: newOrder.logisticsInfos }
              );
            } else {
              // Jika orderId tidak ditemukan, tambahkan newOrder sebagai objek baru di ship
              this.ship.push({ externalOrderId: newOrder.externalOrderId },
                { channel: newOrder.channel },
                { logisticsInfos: newOrder.logisticsInfos });
            }
          });

          this.ship.forEach(order => {

            order.logistics.forEach(logistic => {
              if (logistic.logisticDetailList.length > 0) {  // Pastikan logisticDetailList tidak kosong
                switch (logistic.logisticsDeliveryType) {
                  case "PICK_UP":
                    this.pickUp.push(order);
                    this.pickUp.map((order) => {
                      order.selected = null
                      order.selected2 = null
                    })
                    break;
                  case "DROP_OFF":
                    this.dropOff.push(order);
                    break;
                  case "MANUAL_SHIP":
                    this.manualShip.push(order);
                    break;
                }
              }
            });
          });
          console.log("PICK_UP:", this.pickUp);
          console.log("DROP_OFF:", this.dropOff);
          console.log("MANUAL_SHIP:", this.manualShip);



          this.loading = false
          // this.getGineeChannel()
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.loading = false
        });
    },
    aturmass() {
      this.$refs.aturmassModal.show();
    }
  },
  async mounted() {
  },
  async created() {
    this.pickUp = null
    this.dropOff = null
    this.manualShip = null
    this.pickUp = []
    this.dropOff = []
    this.manualShip = []
    // Di halaman baru
    this.itemData = JSON.parse(sessionStorage.getItem('itemDatapengiriman'));
    const data = this.itemData.map(order => order.orderId);
    this.getShip(data)
    // Jangan lupa menghapus data setelah digunakan jika diperlukan
    sessionStorage.removeItem('itemData');

  },
};
</script>
